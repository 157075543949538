.amp {
    font-family:Garamond,Baskerville,Georgia,serif !important;
    font-style:italic;
    font-weight:normal;
    border: none;
}

div.polaroid {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 10px 10px 20px 10px;
    width: 150px;
    border: 1px solid #BFBFBF;
    background-color: white;
    z-index: 2;
    font-size: 0.7em;
    -webkit-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    -moz-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    -webkit-transition: -webkit-transform 0.5s ease-in;
}
div.polaroid:hover,
div.polaroid:focus,
div.polaroid:active {
    z-index: 999;
    border-color: #6A6A6A;
    -webkit-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    -moz-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    transform: rotate(0deg);
}
.polaroid img {
    margin: 0 0 15px;
    width: 150px;
    height: 150px;
}

.photo-album {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 450px;
    margin-top: 5em;
}
.photo-album div img {
    border: none;
    display: block;
}
.photo-album .polaroid {
    position: absolute;
}
.photo-album h1 {
    position: absolute;
    z-index: 5;
    top: 150px;
    text-align: center;
    width: 100%;
    line-height: 1.9;
}
.photo-album h1 span {
    background-color: white;
    font-family: "Helvetica Neue",Arial,Helvetica,sans-serif;
    padding: 0.4em 0.8em 0.3em 0.8em;
    -webkit-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    -moz-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
    border: 1px solid #6A6A6A;
}
.photo-album .small {
    width: 90px;
    padding: 6px 6px 12px 6px;
    font-size: 0.6em;
}
.photo-album .small img {
    width: 75px;
    height: 75px;
}
.photo-album .medium {
    width: 206px;
    padding: 13px 13px 26px 13px;
    font-size: 1em;
}
.photo-album .medium img {
    height: 180px;
    width: 180px;
}
.photo-album .large {
    width: 340px;
    padding: 20px 20px 30px 20px;
    font-size: 1em;
}
.photo-album .large img {
    width: 300px;
    height: 300px;
}
.photo-album p{
    line-height: 1em;
}

.photo-album .rot0 {
    transform: rotate(-20deg);
}
.photo-album .rot1 {
    transform: rotate(-18deg);
}
.photo-album .rot2 {
    transform: rotate(-16deg);
}
.photo-album .rot3 {
    transform: rotate(-14deg);
}
.photo-album .rot4 {
    transform: rotate(-12deg);
}
.photo-album .rot5 {
    transform: rotate(-10deg);
}
.photo-album .rot6 {
    transform: rotate(-8deg);
}
.photo-album .rot7 {
    transform: rotate(-6deg);
}
.photo-album .rot8 {
    transform: rotate(-4deg);
}
.photo-album .rot9 {
    transform: rotate(-2deg);
}
.photo-album .rot10 {
    transform: rotate(2deg);
}
.photo-album .rot11 {
    transform: rotate(4deg);
}
.photo-album .rot12 {
    transform: rotate(6deg);
}
.photo-album .rot13 {
    transform: rotate(8deg);
}
.photo-album .rot14 {
    transform: rotate(10deg);
}
.photo-album .rot15 {
    transform: rotate(12deg);
}
.photo-album .rot16 {
    transform: rotate(14deg);
}
.photo-album .rot17 {
    transform: rotate(16deg);
}
.photo-album .rot18 {
    transform: rotate(18deg);
}
.photo-album .rot19 {
    transform: rotate(20deg);
}

div:hover,
div:focus {
    z-index: 5;
}
